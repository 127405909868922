<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio documento</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="documento">
                    <div class="field">
                        <div class="title document_title">{{ documento.documenti_dipendenti_nome_documento }}</div>
                    </div>
                    <Field title="Data documento" :value="documento.documenti_dipendenti_data_documento" type="date" />
                    <Field title="Data scadenza" :value="documento.documenti_dipendenti_data_scadenza" type="date" />
                    <Field
                        title="Categoria"
                        :value="documento.documenti_dipendenti_sottocategorie_value ? documento.documenti_dipendenti_sottocategorie_value : '-'"
                        type="text"
                    />
                    <Field title="Data documento" :value="documento.documenti_dipendenti_data_documento" type="date" />
                    <!-- <Field title="Testo" :value="documento.documenti_dipendenti_testo ? documento.documenti_dipendenti_testo : '-'" type="html" /> -->
                    <!-- <div class="field">
                        <div class="title">Data documento</div>
                        <div class="value">
                            {{ dateFormat(documento.documenti_dipendenti_data_documento) }}
                        </div>
                    </div> -->
                    <!-- <div class="field">
                        <div class="title">Data scadenza</div>
                        <div class="value">
                            {{ dateFormat(documento.documenti_dipendenti_data_scadenza) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Categoria</div>
                        <div class="value">
                            {{ documento.documenti_dipendenti_sottocategorie_value ? documento.documenti_dipendenti_sottocategorie_value : "-" }}
                        </div>
                    </div> -->
                    <div v-if="documento.documenti_dipendenti_testo" class="field field_column">
                        <div class="title">Testo</div>
                        <div class="value testo">
                            <div v-html="documento.documenti_dipendenti_testo"></div>
                        </div>
                    </div>
                    <!-- <div v-if="documento.documenti_dipendenti_file" class="field">
                        <a :href="setAttachmentUrl(documento)" target="_blank" class="download_allegato">
                            Scarica documento
                        </a>
                    </div> -->
                    <Field
                        v-if="documento.documenti_dipendenti_file"
                        title="Scarica documento"
                        :value="documento.documenti_dipendenti_file"
                        type="action"
                        :generateLink="true"
                        :fieldLink="documento.documenti_dipendenti_file"
                    />
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent } from "vue";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";

import Field from "@/components/general/field/Field.vue";

export default defineComponent({
    name: "Dettaglio documento",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        Field,
    },
    setup(props, context) {
        //console.log(props.data);
        const documento = { ...props.data };
        //console.log(documento);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        function setAttachmentUrl(documento) {
            return `${process.env.VUE_APP_BASE_URL}uploads/${documento.documenti_dipendenti_file}`;
        }

        return {
            dateFormat,
            dateFormatWithMinutes,
            documento,
            arrowBackOutline,
            closeModal,
            setAttachmentUrl,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new movimento btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

.documento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 10px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    box-shadow: 0px 2px 5px 0px #00000040;
}

.documento .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 0.5px solid #7676803d;
}
.documento .field.field_column {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0;
    border-bottom: 0.5px solid #7676803d;
}
.documento .field.field_column .value.testo {
    font-weight: 400;
    font-size: 14px;
    color: #979797;
}

.documento .field:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
}
.documento .field:first-of-type {
    border-bottom: 0;
    margin-bottom: 0;
}
.field .title {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.field .value {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1b;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.value .badge_success {
    background-color: #22c55e;
}
.value .badge_warning {
    background-color: #f97316;
}
.value .badge_danger {
    background-color: #ef4444;
}

ion-button {
    --color: #ffffff;
}

.download_allegato {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    background-color: #086fa3;
    text-align: center;
    text-decoration: none;
    border-radius: 20px;
}
.no_articoli_container {
    width: 100%;
    padding-top: 8px;
}
.no_articoli {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

.field .document_title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    border-bottom: none;
}
</style>
