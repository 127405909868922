<template>
    <div v-if="tipo !== 'action'" class="field">
        <div class="title">{{ titolo }}</div>
        <div class="value">
            <span v-if="tipo === 'text'">
                {{ valore }}
            </span>
            <span v-if="tipo === 'date'">
                {{ dateFormat(valore) }}
            </span>
            <span v-if="tipo === 'html'">
                <span v-html="valore"> </span>
            </span>
        </div>
    </div>
    <div v-else class="field">
        <a :href="link()" target="_blank" class="field_link">
            {{ titolo }}
        </a>
    </div>
</template>

<script>
import { defineComponent } from "vue";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";

export default defineComponent({
    props: {
        title: String,
        value: [String, Number],
        type: String,
        generateLink: Boolean,
        fieldLink: String,
    },
    setup(props) {
        console.log(props.action);

        function link() {
            return `${process.env.VUE_APP_BASE_URL}uploads/${props.fieldLink}`;
        }

        return {
            titolo: props.title,
            valore: props.value,
            tipo: props.type,
            dateFormat,
            generate: props.generateLink,
            link,
        };
    },
});
</script>

<style scoped>
.field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 0.5px solid #7676803d;
}

.title {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}

.value {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1b;
}

.field_link {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    background-color: #086fa3;
    text-align: center;
    text-decoration: none;
    border-radius: 20px;
}
</style>
